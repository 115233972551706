export const INDICATORS = [

    {
        name: 'BELEIDSVISIE',
        categories: [
            /* 1. */
            {
                name: <div>De hogeschool/universiteit beschikt over een <b>formele beleidsvisie</b> omtrent ongewenst
                    grensoverschrijdend gedrag bij/d.m.v. studenten.</div>,
                subCategories: [
                    {
                        name: <div>De tekst is <b>uitgeschreven</b>. Hij is het resultaat van een breder overleg en is
                            goedgekeurd op het hoogste beleidsniveau.</div>,
                        weight: 1
                    },
                    {
                        name: <div>De tekst bevat een <b>heldere definitie</b> van <b>ongewenst grensoverschrijdend
                            gedrag</b>.</div>,
                        weight: 1
                    }
                ]
            },

            /* 2. */
            {
                name: <div>De beleidsvisie neemt een <b>volwaardige plaats in en koppelt zich aan andere aspecten van
                    het
                    ruimere hogeschoolbeleid/universitaire beleid.</b></div>,
                subCategories: [
                    {
                        name: <div>De beleidsvisie dringt door tot in het <b>Onderwijs- en examenreglement</b> en andere
                            documenten die <b>het DNA en de omgangscultuur</b> van de hogeschool/universiteit gestalte
                            geven.</div>,
                        weight: 1
                    },
                    {
                        name: <div><b>Het beleid</b> inzake ongewenst grensoverschrijdend gedrag <b>kadert binnen het
                            ruimere
                            zorg- en welzijnsgerichte beleid</b> van de hogeschool/universiteit en koppelt zich derhalve
                            aan
                            <b>andere beleidsonderdelen</b> (vb. studentenbegeleiding, suïcidepreventieplan,
                            welzijnsbeleid
                            personeel en studenten).</div>,
                        weight: 1
                    }
                ]
            },

            /* 3. */
            {
                name: <div>De beleidsvisie valt onder de noemer van een <b>hogeschool-brede/universiteit-brede
                    aanpak</b> (integrale aanpak).</div>,
                subCategories: [
                    {
                        name: <div>Het hogeschool-brede of universitair-brede beleid richt zich ondubbelzinnig tot en
                            betrekt alle betekenisvolle actoren: leidinggevenden, medewerkers, studenten,
                            residentieverantwoordelijken en alle actoren die hogeschoolnabij of universiteitsnabij
                            zijn.</div>,
                        weight: 1
                    },
                    {
                        name: <div>
                            Het beleid combineert alle deelelementen van een integrale aanpak, m.n.
                            <ol className={'mb-0'}>
                                <li>Preventiegerichte maatregelen</li>
                                <li>Maatregelen die het melden, herkennen en/of signaleren van situaties van ongewenst
                                    grensoverschrijdend gedrag faciliteren
                                </li>
                                <li>Indicatieve acties</li>
                                <li>Doorverwijzing en nazorg/opvolging</li>
                            </ol>
                        </div>,
                        weight: 4,
                        tooltip: <div>
                            Een hogeschool-brede aanpak realiseert zich door het combineren van diverse initiatieven en
                            acties.
                            <ul>
                                <li>Een kwaliteitsvol beleid investeert primair in het tot stand brengen van een
                                    warm-menselijke omgangscultuur en positieve relaties tussen studenten onderling en
                                    met de
                                    medewerkers van de instelling (positieve werk- en leeromgeving).
                                </li>
                                <li>Daarnaast wordt werk gemaakt van een reeks preventieve acties en initiatieven:
                                    zij mikken op het versterken van de menselijke en de structurele veerkracht en
                                    competenties
                                    van de betrokkenen (algemene preventie). Voorbeelden:
                                    <ul>
                                        <li>Organiseren van informele contactmomenten</li>
                                        <li>Kwaliteitscirkels</li>
                                        <li>Opleiden van buddy’s</li>
                                        <li>Tevredenheidsmetingen</li>
                                        <li>Nabij brengen van het begeleidingsaanbod</li>
                                        <li>Afspraken i.v.m. veilig en correct online-contact</li>
                                        <li>…</li>
                                    </ul>
                                </li>
                                <li>Een deel van de preventieve acties informeert en sensibiliseert over ongewenst
                                    grensoverschrijdend gedrag en dringt aan op het nemen van verantwoordelijkheid.
                                    Voorbeelden:
                                    <ul>
                                        <li>Sensibiliserende activiteiten: stickers, posters, online mededelingen,
                                            getuigenissen, …
                                        </li>
                                        <li>Mental coaches</li>
                                        <li>Informatie delen met uitleg over de visie, de aanspreekpunten en de
                                            procedures binnen de eigen instelling.
                                        </li>
                                        <li>Hulpfiches aanbieden</li>
                                        <li>Infoberichten plaatsen op het intranet</li>
                                        <li>Bewonersvergaderingen (in residenties)</li>

                                    </ul>
                                </li>
                            </ul>

                            <b>Maatregelen gericht op vroegdetectie/opmerken van situaties waarin sprake is van
                                OGOG:</b>

                            <ul>
                                <li>Mentorgesprekken (officieel F2F-gesprekken)</li>
                                <li>Nauwgezette opvolging door coaches/studentendienst</li>
                                <li>Informele F2F- of (klas)groepgesprekken (vb. tijdens uitstappen)</li>
                                <li>Aandacht voor het functioneren van taakgerichte groepen</li>
                                <li>Inschakelen van vrijwillige ‘voelsprietstudenten’ (cf. Mindmates) in elke
                                    opleiding
                                </li>

                            </ul>


                            <b>Indicatieve acties </b>
                            <ul>
                                <li>No Blame</li>
                                <li>Herstelgesprek/herstelcirkel</li>
                            </ul>

                            <b>Doorverwijzing en nazorg/opvolging</b>
                            <ul>
                                <li>Het ter beschikking stellen/houden van een sociale kaart met daarop alle
                                    contactgegevens van hulpverleners, organisaties, de lokale politie, etc…
                                </li>
                            </ul>

                        </div>
                    }
                ]
            },
            {
                name: <div>De beleidsvisie geeft <b>voorrang aan een oplossings- en herstelgerichte aanpak.</b></div>,
                subCategories: [
                    {
                        name: <div>De vertrouwenspersonen studenten werken de-escalerend en verkennen prioritair of het
                            ervaren conflict op een informele wijze kan worden opgelost (via een oplossings- en/of
                            herstelgericht gesprek). </div>,
                        weight: 0.5
                    },
                    {
                        name: <div>Als de oplossingsgerichte en herstelgerichte aanpak niet volstaat of onmogelijk
                            blijkt, wordt overgegaan naar het onderwijs-en examenreglement en volgt er mogelijk een
                            disciplinaire tuchtmaatregel vanwege de bevoegde leidinggevende.</div>,
                        weight: 0.5
                    },
                ]
            },
        ]
    },

    {
        name: 'PARTICIPATIE & GEDRAGENHEID',
        label: ['PARTICIPATIE &', 'GEDRAGENHEID'],
        categories: [
            {
                name: <div><b>Het bestuur en de opleidingsverantwoordelijken staan ondubbelzinnig achter de
                    beleidsvisie</b> en
                    de aanpak die er uit voor vloeit. (‘Geëngageerd beleidsteam').</div>,
                subCategories: [
                    {
                        name: <div><b>Het hogeschool-/universitair bestuur draagt de beleidsvisie op diverse wijzen
                            uit.</b></div>,
                        weight: 1
                    },
                    {
                        name: <div><b>Het hogeschool-/universitair bestuur kijkt er op toe dat de visie ingang
                            vindt</b> in de
                            werking van de hogeschool/universiteit en <b>levert inspanningen</b> om dit mogelijk te
                            maken. </div>,
                        weight: 1
                    }
                ]
            },
            {
                name: <div>Een <b>werkgroep</b> volgt het beleid inzake ongewenst grensoverschrijdend gedrag
                    systematisch op,
                    formuleert suggesties en aanbevelingen, etc… . </div>,
                subCategories: [
                    {
                        name: <div>De werkgroep is divers samengesteld en telt onder haar leden zowel
                            beleidsmedewerkers, docenten, studentenbegeleiders en studenten.
                        </div>,
                        weight: 1
                    },
                    {
                        name: <div>De werkgroep heeft een formeel mandaat, mag initiatief ontwikkelen en kan rekenen op
                            het gehoor van de leidinggevenden.
                        </div>,
                        weight: 1
                    },
                    {
                        name: <div>De werkgroep toont jaar na jaar initiatief en evalueert haar eigen werking.</div>,
                        weight: 1
                    },
                ]
            },
            {
                name: <div>De hogeschool/universiteit kiest er voor <b>om alle studenten en medewerkers zo nauw mogelijk
                    te
                    betrekken</b> bij het beleid inzake ongewenst grensoverschrijdend gedrag (principiële keuze
                    voor <b>PARTICIPATIE</b>). </div>,
                subCategories: [
                    {
                        name: <div>De instelling kiest er voor om alle studenten en medewerkers zo nauw mogelijk te
                            betrekken <b>bij het ontwerpen van het beleid.</b></div>,
                        weight: 1
                    },
                    {
                        name: <div>De instelling kiest er voor om alle studenten en medewerkers zo nauw mogelijk te
                            betrekken <b>bij het implementeren</b> (informeren, sensibiliseren en installeren) van het
                            beleid.</div>,
                        weight: 1
                    },
                    {
                        name: <div>De instelling kiest er voor om alle studenten en medewerkers, voor zover mogelijk en
                            haalbaar, te betrekken <b>bij het detecteren/signaleren en/of vroegtijdig opsporen van
                                incidenten</b> van ongewenst grensoverschrijdend gedrag.</div>,
                        weight: 1
                    },
                    {
                        name: <div>De instelling kiest er voor om studenten en medewerkers <b>actief te betrekken bij de
                            aanpak</b> van meldingen/incidenten van ongewenst grensoverschrijdend gedrag. </div>,
                        weight: 1
                    },
                    {
                        name: <div>De instelling kiest er voor om studenten en medewerkers te betrekken bij de
                            nazorg/verdere opvolging van incidenten of meldingen van ongewenst grensoverschrijdend
                            gedrag. </div>,
                        weight: 1
                    },
                ]
            },
        ]
    },

    {
        name: 'COMMUNICATIE',
        categories: [
            {
                name: <div>Dat de hogeschool/universiteit inzet op het voorkomen, bespreekbaar maken en zorgzaam
                    aanpakken van ongewenst grensoverschrijdend gedrag toont zich op diverse wijzen. <b>(VISIBILITEIT
                        VAN
                        HET BELEID)</b></div>,
                subCategories: [
                    {
                        name: <div><b>Studenten en medewerkers worden</b> aanhoudend, meervoudig, op maat en <b>op een
                            visibele
                            manier geïnformeerd en gesensibiliseerd</b> omtrent de visie en het zorgaanbod van de
                            hogeschool/universiteit.</div>,
                        weight: 4,
                        tooltip: <div>
                            Aandachtspunten bij het communiceren naar studenten:
                            <ul>
                                <li>Zorg voor communicatie op maat (per doelgroep: eerstejaars benader je anders dan de
                                    anciens.)
                                </li>
                                <li>Vermijd een teveel aan communicatie in de eerste weken van het academiejaar omdat de
                                    nieuwe studenten dan teveel informatie te verwerken krijgen. Ook tijdens de
                                    onthaaldagen komt de info over ongewenst grensoverschrijdend gedrag dan te vroeg.
                                </li>
                                <li>Vermijd een overload aan mails.</li>
                                <li>Interessant zijn de tijdelijke pop-up-berichten: die attenderen effectiever.</li>
                                <li>Maak werk van taboedoorbrekende en ‘normaliserende’ communicatie.</li>
                                <li>Plaats essentiële info zo dat ze zich vlot laat vinden (vooraan/bovenaan, vlot te
                                    vinden)
                                </li>
                                <li>Maak gebruik van stickers, GETUIGENISSEN, flyers, online info, opvallende affiches
                                    of een ludieke, speelse teaser op het intranet.
                                </li>
                            </ul>
                        </div>
                    },
                    {
                        name: <div>De hogeschool/universiteit maakt ook via haar <b>online communicatie</b> (website,
                            Twitter,
                            nieuwsbrieven, …) duidelijk hoe ze zich opstelt t.a.v. ongewenst grensoverschrijdend
                            gedrag. </div>,
                        weight: 1
                    },
                ]
            },
            {
                name: <div>De hogeschool/universiteit levert <b>met grote regelmaat/duurzaam inspanningen</b> om haar
                    beleid
                    inzake ongewenst grensoverschrijdend gedrag en het daarbij aansluitende hulpaanbod <b>tot bij de
                        ‘gebruikers’</b> (studenten, medewerkers, stageplaatsen) <b>te brengen.</b></div>,
                subCategories: [
                    {
                        name: <div><b>Docenten en andere medewerkers</b> worden <b>systematisch en op meerdere
                            manieren</b> (on- en
                            offline) <b>geïnformeerd</b> en gesensibiliseerd over hun rol en verantwoordelijkheid binnen
                            het
                            beleid inzake ongewenst grensoverschrijdend gedrag bij/door studenten. </div>,
                        weight: 1
                    },
                    {
                        name: <div>Studenten worden systematisch en op meerdere manieren (on- en offline) geïnformeerd
                            over het beleid inzake ongewenst grensoverschrijdend gedrag bij/door studenten. Ze vernemen
                            hierbij wat hen desgevallend te doen staat. </div>,
                        weight: 1
                    },
                ]
            },
            {
                name: <div>De <b>vertrouwenspersonen studenten komen visueel in beeld</b>: studenten krijgen het gezicht
                    van de
                    vertrouwenspersonen te zien. </div>,
                subCategories: [
                    {
                        name: null, // TODO
                        weight: 1
                    },
                ]
            },
            {
                name: <div>De hogeschool/universiteit <b>voorziet in en promoot</b> een makkelijk bereikbaar en vlot
                    toegankelijk <b>digitaal meldpunt</b>.</div>,
                tooltip: <div>
                    <p>Iedereen die met een probleem zit moet weten dat de hogeschool klaar staat om hem/haar/die te
                        helpen.
                        Het meldpunt laat zich idealiter vlot vinden en is dag en nacht beschikbaar.</p>

                    <p>Studenten willen de gezichten achter het meldpunt zien en weet hebben van de stappen die er na
                        een melding gezet zullen/kunnen worden.</p>

                    <p>Na elke (digitale of andere) melding volgt er in principe altijd een gesprek met de persoon die
                        contact nam.</p>
                </div>,
                subCategories: [
                    {
                        name: <div><b>Er staat een digitaal meldpunt ter beschikking</b> van al wie melding wil maken
                            van
                            situaties van ongewenst grensoverschrijdend gedrag met betrokkenheid van studenten. </div>,
                        weight: 1
                    },
                    {
                        name: <div>Het digitaal meldpunt is <b>vlot terug te vinden</b> op het intranet van de
                            hogeschool/universiteit voor wie er naar op zoek is. </div>,
                        weight: 1
                    },
                ]
            },
        ]
    },


    {
        name: 'SAMENWERKING',
        categories: [
            {
                name: <div>
                    De hogeschool/universiteit maakt werk van een positieve omgangs- en samenwerkcultuur met aandacht
                    voor goede relaties, transparantie en openheid.
                </div>,
                tooltip: <div>
                    <p>Studenten en medewerkers voelen zich gerespecteerd en beluisterd.</p>
                    <p>Het beleid heeft oren naar wat zij aangeven/nodig hebben en inspireert zich aan de feedback die
                        binnen komt.</p>
                    <p>De instelling wil verantwoordelijkheid delen.</p>
                </div>,
                subCategories: [
                    {
                        name: <div>Studenten en medewerkers worden aangemoedigd tot een goed formeel en informeel
                            contact met oog voor respect, waardering en samenwerking.</div>,
                        weight: 1
                    },
                    {
                        name: <div>
                            Het beleid trekt de kaart van inspraak, betrokkenheid en medeverantwoordelijkheid. Er wordt
                            rekening gehouden met de input die studenten en medewerkers geven.</div>,
                        weight: 1
                    },
                ]
            },
            {
                name: <div>De hogeschool/universiteit beschikt over een <b>samenwerkingsprotocol</b>. Dit protocol
                    schetst de
                    gewenste samenwerking tussen al wie betrokken is bij het beleid inzake de aanpak van ongewenst
                    grensoverschrijdend gedrag en bevat tevens de procedures (zie verder) die gehanteerd (zullen)
                    worden. </div>,
                subCategories: [
                    {
                        name: <div>Alle betrokkenen <b>weten wat er van hen wordt verwacht (preventie,
                            signalering/melding,
                            opvang probleemsituatie, nazorg/opvolging)</b>. </div>,
                        weight: 2
                    },
                    {
                        name: <div>Alle betrokkenen weten <b>naar wie</b> ze zo nodig kunnen <b>doorverwijzen</b> of op
                            wie ze kunnen
                            terugvallen. </div>,
                        weight: 1
                    },
                    {
                        name: <div>Alle betrokkenen (vertrouwenspersonen, andere medewerkers, studenten) kunnen
                            terugvallen op transparante en werkbare <b>procedures</b> voor het melden, doorverwijzen en
                            verder
                            aanpakken van meldingen of situaties waarin (mogelijk) sprake is van ongewenst
                            grensoverschrijdend gedrag.
                            (Helder protocol)
                        </div>,
                        weight: 2,
                        tooltip:
                            <div>
                                <p>Deze procedures dienen op teamniveau te worden besproken en gefinetuned. Er wordt
                                    voorzien in een keten aan zorg/ begeleiding.</p>

                                <p>De procedures verhelderen op voorhand hoe er wordt gewerkt, wat er volgt op een
                                    melding
                                    of klacht, … zodat men als ‘melder’ gerust kan zijn over het verdere verloop.</p>
                            </div>

                    },
                ]
            },
            {
                name: <div>De vertrouwenspersoon studenten (wie instaat voor de opvangen en aanpakken van situaties van
                    ongewenst grensoverschrijdend gedrag bij/door toedoen van studenten) beschikt over een <b>duidelijke
                        functieomschrijving en dito situering binnen de begeleidings- en zorgstructuur</b> van de
                    hogeschool/universiteit.</div>,
                subCategories: [
                    {
                        name: null, // TODO
                        weight: 1
                    },
                ]
            },
            {
                name: <div>In het kader van haar beleid omtrent ongewenst grensoverschrijdend gedrag werkt de
                    hogeschool/universiteit samen met een <b>netwerk van externe deskundigen/partners</b>.</div>,
                subCategories: [
                    {
                        name: <div>De instelling beschikt over een ‘<b>sociale kaart</b>’ die helder maakt met welke
                            partners
                            en experten er in het kader van ongewenst grensoverschrijdend gedrag kan worden
                            samengewerkt.</div>,
                        weight: 1
                    },
                    {
                        name: <div>De instelling zet in op het warm/open houden van de contacten met deze externe
                            partners en hanteert hiervoor <b>samenwerkingsafspraken</b>.</div>,
                        weight: 1
                    },
                ]
            },
        ]
    },

    {
        name: 'TIJD EN MIDDELEN',
        label: ['TIJD EN', 'MIDDELEN'],
        categories: [
            {
                name: <div>Het hogeschool-/universitair bestuur voorziet in de vereiste <b>omkadering</b> (VTE’s, tijd,
                    middelen, werkruimte, …) voor het kwaliteitsvol realiseren van haar beleidsvisie. </div>,
                tooltip: <div>De hogeschool/universiteit streeft er naar de vertrouwenspersonen maximaal ter beschikking
                    te stellen (bijvoorbeeld via open office hours & telefonische permanentie) zodat gezorgd kan worden
                    voor het snel opvolgen van meldingen en incidenten. </div>,
                subCategories: [
                    {
                        name: <div>De vertrouwenspersonen beschikken over de <b>nodige tijd (opdrachtpercentage) en
                            werkruimte</b> om zich te kunnen professionaliseren en hun taak naar behoren te kunnen
                            uitvoeren. </div>,
                        weight: 2
                    },
                    {
                        name: <div>De hogeschool/universiteit maakt de nodige middelen en werktijd vrij voor een
                            <b>kwaliteitsvol preventiebeleid</b> inzake ongewenst grensoverschrijdend gedrag. </div>,
                        weight: 1
                    },

                ]
            },
            {
                name: <div>De hogeschool/universiteit zorgt er voor dat <b>iedereen die er nood aan heeft kan rekenen op
                    een face-to-face-gesprek met een deskundige.</b></div>,
                subCategories: [
                    {
                        name: <div>De vertrouwenspersonen zijn flexibel inzetbaar en nabij: ze nemen altijd en <b>binnen
                            een relatief korte tijdspanne contact</b> (online/telefonisch) met de aanmelder. </div>,
                        weight: 1
                    },
                    {
                        name: <div>In principe volgt er kort na de eerste contactneming een <b>F-2-F-gesprek</b> plaats
                            tussen
                            de melder en de vertrouwenspersoon. Dit gesprek vindt plaats in een ruimte
                            die <b>discretie</b>
                            garandeert. </div>,
                        weight: 1
                    },
                ]
            },
            {
                name: <div>De hogeschool/universiteit zorgt voor de nodige <b>omkadering</b>
                    (tewerkstellingspercentage/erkenning) voor de leden van de <b>werkgroep</b> die het beleid inzake
                    ‘welzijn’
                    op de hogeschool in handen neemt. </div>,
                subCategories: [
                    {
                        name: null, // TODO
                        weight: 1
                    },
                ]
            },
            {
                name: <div>
                    <b>
                    De vertrouwenspersonen studenten kunnen rekenen op de noodzakelijke professionele ondersteuning.
                </b>
                </div>,
                subCategories: [
                    {
                        name: <div>De vertrouwenspersonen krijgen een degelijke basisopleiding.</div>,
                        weight: 1
                    },
                    {
                        name: <div>De vertrouwenspersonen krijgen een degelijke basisopleiding.</div>,
                        weight: 1
                    },
                ]
            },
            {
                name: <div>De hogeschool/universiteit kijkt er op toe dat <b>er op maat, op meerdere manieren
                    (online/offline) en succesvol over (het beleid inzake) ongewenst grensoverschrijdend gedrag wordt
                    gecommuniceerd. </b></div>,
                subCategories: [
                    {
                        name: <div>De hogeschool/universiteit investeert in moderne communicatie en
                            informatiestrategieën om de verschillende doelgroepen gepast te informeren en te
                            sensibiliseren. </div>,
                        weight: 1
                    },
                    {
                        name: <div>De hogeschool/universiteit evalueert de wijze(n) waarop er met de diverse betrokkenen
                            gecommuniceerd wordt en is bereid om haar communicatie desnoods aan te passen. </div>,
                        weight: 1
                    },
                ]
            },

        ]
    },

    {
        name: 'PROFESSIONELE VORMING',
        label: ['PROFESSIONELE', 'VORMING'],
        categories: [
            {
                name: <div>De hogeschool/universiteit past de <b>PDCA-cyclus</b> toe op haar beleid inzake ongewenst
                    grensoverschrijdend gedrag. </div>,
                subCategories: [
                    {
                        name: <div>De hogeschool/universiteit <b>volgt het beleid inzake geestelijke gezondheid en
                            welbevinden goed op en verbindt acties aan de vaststellingen die worden gemaakt</b>.</div>,
                        weight: 2
                    },
                    {
                        name: <div>Op basis van het registratiedossier wordt jaarlijks, aan het eind van het
                            academiejaar, een <b>samenvattende nota</b> bezorgd aan het leidinggevende team. Die nota
                            bevat
                            kenmerkt zich door het aanreiken van aantal <b>aanbevelingen en suggesties.</b></div>,
                        weight: 2
                    },
                ]
            },
            {
                name: <div><b>Zowel op instellingsniveau als binnen elke opleiding</b> wordt ingezet op het <b>informeren,
                    sensibiliseren en activeren van alle betrokkenen</b>.</div>,
                subCategories: [
                    {
                        name: <div><b>Bij aanvang van het academiejaar worden alle nieuwe medewerkers en studenten
                            geïnformeerd over het beleid</b> inzake de aanpak van ongewenst grensoverschrijdend gedrag.
                            Ze
                            worden geïnformeerd en attent gemaakt voor het opnemen van hun eigen verantwoordelijkheid.
                            Ze vernemen waar en bij wie ze terecht kunnen, mocht zich een probleem voordoen.</div>,
                        weight: 2,
                        tooltip: <div>
                            Wat zeker aan bod moet komen:
                            <ul>
                                <li>Heldere definitie</li>
                                <li>Signalen herkennen</li>
                                <li>Wat te doen als?</li>
                                <li>Wie is aanspreekpunt?</li>
                                <li>Wat gebeurt er met je melding/als je een gesprek wil aangaan?</li>
                            </ul>

                        </div>
                    },
                    {
                        name: <div>
                            Studenten en medewerkers zijn op de hoogte van het beleid inzake ongewenst
                            grensoverschrijdend gedrag binnen hun instelling en <b>weten wat hen te doen staat</b> om te
                            voorkomen dat er situaties ontstaan waarin sprake is van ongewenst grensoverschrijdend
                            gedrag. (PREVENTIE – kennis en attitude)
                        </div>,
                        weight: 2,
                        tooltip: <div>
                            De kwaliteit van het hulp- en zorgaanbod hangt nauw samen met
                            <ul>
                                <li>de persoonsgerichte betrokkenheid van de medewerker(s);</li>
                                <li>de veiligheid die geboden wordt aan wie iets komt melden/een klacht neerlegt;</li>
                                <li>de snelheid van reageren door de hulpverleners: het opnemen van een probleem/ de
                                    permanentie die wordt aangeboden;
                                </li>
                                <li>de (bewezen) deskundigheid van de medewerker</li>
                            </ul>
                        </div>
                    },
                    {
                        name: <div>Opleidingen leggen <u>waar mogelijk</u> een koppeling tussen het gevoerde beleid en
                            het <b>curriculum</b>. (PREVENTIE</div>,
                        weight: 1
                    },
                ]
            },
            {
                name: <div>De vertrouwenspersonen studenten
                    hebben een duidelijk <b>statuut (opdrachtomschrijving, functieprofiel, deontologisch statuut)</b>.
                </div>,
                subCategories: [
                    {
                        name: null,
                        weight: 1
                    },
                ]
            },
        ]
    },

    {
        name: 'EVALUATIE EN BIJSTURING',
        label: ['EVALUATIE EN', 'BIJSTURING'],
        categories: [
            {
                name: <div>De hogeschool/universiteit peilt op regelmatige basis en via een <b>systematische bevraging
                    het
                    welbevinden van de studenten</b>, incl. hun ervaringen (prevalentie) op het vlak van ongewenst
                    grensoverschrijdend gedrag. </div>,
                subCategories: [
                    {
                        name: <div><b>De hogeschool/universiteit polst systematisch naar de geestelijke gezondheid van
                            al
                            haar studenten.</b></div>,
                        weight: 2
                    },
                    {
                        name: <div>De hogeschool/universiteit informeert in de bevraging (zie hoger) van haar studenten
                            <b>expliciet</b> naar hun <b>ervaringen met ongewenst grensoverschrijdend gedrag</b> binnen
                            de context van
                            de hogeschool/universiteit. </div>,
                        weight: 1
                    },
                ]
            },
            {
                name: <div>De hogeschool/universiteit zet in op het <b>registeren en rapporteren van alle meldingen en
                    klachten</b> i.f.v. het kwaliteitsvol opvolgen van het beleid inzake ongewenst grensoverschrijdend
                    gedrag.</div>,
                subCategories: [
                    {
                        name: <div>De vertrouwenspersonen studenten stellen aan het eind van elk academiejaar een
                            <b>samenvattend rapport</b> op met een overzicht van alle meldingen en incidenten die tot
                            bij hen
                            zijn gekomen. </div>,
                        weight: 1.5
                    },
                    {
                        name: <div>De vertrouwenspersonen studenten formuleren op basis van de beschikbare informatie,
                            ervaringen en evaluaties <b>beleidsgerichte conclusies, aanbevelingen en voorstellen</b> die
                            er op
                            gericht zijn het gevoerde beleid te versterken of kwaliteitsvol te continueren. </div>,
                        weight: 1.5
                    },
                ]
            },
            {
                name: <div>De hogeschool/universiteit luistert, als lerende organisatie, <b>onbevangen en in alle
                    openheid</b> naar de feedback die de betrokkenen haar geeft. </div>,
                subCategories: [
                    {
                        name: <div>De hogeschool/universiteit <b>speelt in op de beleidsgerichte conclusies,
                            aanbevelingen
                            en voorstellen</b> die in het jaarlijks rapport worden aangereikt. </div>,
                        weight: 1
                    },
                    {
                        name: <div>De hogeschool/universiteit treedt op regelmatige basis <b>in gesprek met de
                            werkgroep</b>
                            die instaat voor het beleid inzake ongewenst grensoverschrijdend gedrag en bekijkt in
                            overleg hoe het beleid ondersteund en versterkt kan worden. </div>,
                        weight: 1
                    },
                ]
            },
            {
                name: <div><b>De kwaliteit van het gevoerde beleid</b> inzake de aanpak van ongewenst
                    grensoverschrijdend
                    gedrag wordt door de hogeschool/universiteit <b>systematisch</b> en op diverse wijze <b>getoetst</b>.
                </div>,
                subCategories: [
                    {
                        name: <div>I.f.v. de kwaliteitsbewaking van het beleid inzake ongewenst grensoverschrijdend
                            gedrag polst de hogeschool/universiteit systematisch de mening en ervaringen van <b>studenten.</b></div>,
                        weight: 1
                    },
                    {
                        name: <div>I.f.v. de kwaliteitsbewaking van het beleid inzake ongewenst grensoverschrijdend
                            gedrag polst de hogeschool/universiteit systematisch de mening en ervaringen van haar <b>medewerkers.</b></div>,
                        weight: 1
                    },
                ]
            },
        ]
    },


]
