import React from 'react';
import Modal from 'react-modal';

import './TooltipModal.css';


type Props = {}

const customStyles = {
    content: {
        maxWidth: 800,
        margin: '0 auto',
    },
};

Modal.setAppElement('#root');


const Legende = ({}: Props) => {

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        //subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div className={'d-inline'}>
            <span className={"text-white fw-bold clickable p-2"} onClick={openModal}>
                Legende
            </span>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Extra Info"
            >
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <h3 className={'mb-0'}></h3>
                    <button className={'btn btn-sm btn-dark ms-auto d-block mb-0'} onClick={closeModal}>Sluit Venster
                    </button>
                </div>
                <h5 className={'mb-4'}>TOELICHTING BIJ DE SCOREMOGELIJKHEDEN</h5>

                <p><b>Score 4</b> - Wijst op een <b>hoge mate van tevredenheid</b> over dit item. <b>Het item dat bevraagd wordt,
                    realiseert
                    zich ten volle in de eigen instelling</b>. Het komt er nu vooral op aan de goede werking gaande te
                    houden.
                    <b>Er is sprake van een geïntegreerd, samenhangend en structureel verankerd beleid</b> dat alle betrokkenen
                    bereikt en mee neemt.</p>

                <p><b>Score 3</b> – Deze score drukt uit dat men <b>tevreden</b> is over de manier waarop dit item op dit moment vorm
                    krijgt: de instelling voor hoger onderwijs oordeelt dat ze <b>goed bezig</b> is maar beseft tegelijkertijd
                    dat
                    er <b>nog een groeimarge</b> is. Het komt er op aan het actuele beleid verder te verfijnen, te verdiepen of
                    nog
                    krachtiger aanwezig te stellen. Het beleid krijgt vorm en verankert zich stap voor stap. <b>De
                    instelling
                        is op weg naar een geïntegreerd, consistent en goed uitwerkt beleid.</b></p>

                <p><b>Score 2</b> - Deze score maakt duidelijk dat instelling bezig dit item vorm te geven maar <b>er is nog veel
                    werk aan te winkel</b>. Wat er tot dusver gerealiseerd werd, staat onvoldoende ver en vraagt om verdere
                    uitwerking en/of om koerscorrecties. <b>Er wordt nog te mager, te fragmentair, en/of met onvoldoende
                        overtuiging en kracht ingezet op dit item</b>. Van een structurele aanpak is nog geen sprake.</p>

                <p><b>Score 1</b> - Deze score wijst er op dat <b>dit item amper of niet werd aangepakt of gerealiseerd</b>. Op dit
                    vlak
                    scoort de hogeschool/universiteit absoluut zwak. <b>Het ontbreekt aan beleid.</b></p>
            </Modal>
        </div>
    );
}

export default Legende;
