import React from 'react';
import Collapsible from 'react-collapsible';

import Category from "components/Category";
import {Indicator as IndicatorClass} from "lib/indicator";
import {Category as CategoryClass} from "lib/category";
import caret from "assets/img/caret.svg";

type Props = {
    indicator: IndicatorClass
    questionNumber: number
}

const Indicator = ({indicator, questionNumber}: Props) => {
    return (
        <Collapsible
            transitionTime={200}
            trigger={
                <div className="shadow bg-secondary text-white mt-4 p-3">
                    <div className={'fw-bold text-left mb-0 d-flex justify-content-between'}>
                        <div className={'d-flex align-items-center'}>
                            <img src={caret} className={'caret me-2'}/>
                            {indicator.name}
                        </div>
                        <div className={'ms-2'}>
                            <span className={'text-nowrap fw-normal'}>({
                                indicator.categories
                                    .map(category => category.subCategories.filter(subCategory => subCategory.filledIn).length)
                                    .reduce((partialSum, a) => partialSum + a, 0)
                            }
                                /
                                {
                                    indicator.categories
                                        .map(category => category.subCategories.length)
                                        .reduce((partialSum, a) => partialSum + a, 0)
                                } vragen ingevuld)
                            </span>

                        </div>
                    </div>
                </div>
            }>


            {
                indicator.categories.map((category: CategoryClass, index) => {
                    return <Category key={index} index={questionNumber + index + 1} category={category}/>
                })
            }
        </Collapsible>
    );
}

export default Indicator;
