import React from 'react';
import Likert from 'react-likert-scale';
import {SubCategory as SubCategoryClass} from "lib/sub-category";
import {useChartData} from "hooks/use-chart-data";
import { v4 as uuidv4 } from 'uuid';
import TooltipModal from "components/TooltipModal";


import './SubCategory.css'

type Props = {
    subCategory: SubCategoryClass
    categoryIndex: number
    index: number
}

const SubCategory = ({subCategory, categoryIndex, index}: Props) => {

    const {updateChart} = useChartData()

    const likertOptions = {
        id: uuidv4(),

        responses: [
            {value: 0, text: "Zeer Zwak"},
            {value: 1, text: "Zwak"},
            {value: 2, text: "Goed"},
            {value: 3, text: "Zeer Goed"},
        ],
        onChange: (data: any) => {
            subCategory.setValue(data.value / 3)
            updateChart()
        }
    };

    if (!subCategory.name)
        return (
            <div className="border-bottom bg-secondary-light px-3 pt-3 pb-1">
                <Likert {...likertOptions} />
            </div>
        )

    return (
        <div className="border-bottom mt-3 px-3 pt-3 pb-1 sub-category-container">
            <div className={'text-start sub-category'}>
                {categoryIndex}.{index}. {subCategory.name}
                {
                    subCategory.tooltip &&
                    <TooltipModal tooltip={subCategory.tooltip}/>
                }
            </div>

            <Likert {...likertOptions} />


        </div>
    );
}

export default SubCategory;
