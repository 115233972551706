import React, {useState} from "react";
import {IndicatorCalculator} from "lib/indicator-calculator";
import {INDICATORS} from "config/indicators";

const LanguageContext = React.createContext<any>(null)


export const LanguageProvider = ({children}: any) => {
    const [language, _setLanguage] = useState(window.localStorage.getItem('language') || 'NL')

    const setLanguage = (language: string) => {
        window.localStorage.setItem('language', language)
        console.log('Setting language to', language)
        _setLanguage(language)
    }
    return (
        <LanguageContext.Provider value={{language, setLanguage}}>
            {children}
        </LanguageContext.Provider>
    )
}

export const useLanguage = () => React.useContext(LanguageContext)
