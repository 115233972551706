import React from 'react';
import Modal from 'react-modal';

import './TooltipModal.css';


type Props = {
    tooltip: any
}

const customStyles = {
    content: {
        maxWidth:800,
        margin: '0 auto',
    },
};

Modal.setAppElement('#root');


const TooltipModal = ({tooltip}: Props) => {

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        //subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div className={'d-inline'}>
            <button className={"tooltip-button"} onClick={openModal}>?</button>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Extra Info"
            >
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <h3 className={'mb-0'}></h3>
                    <button className={'btn btn-sm btn-dark ms-auto d-block mb-0'} onClick={closeModal}>Sluit Venster</button>
                </div>

                {tooltip}
            </Modal>
        </div>
    );
}

export default TooltipModal;
