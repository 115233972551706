import React from 'react';

import SubCategory from "components/SubCategory";
import {Category as CategoryClass} from "lib/category";
import {SubCategory as SubCategoryClass} from "lib/sub-category";

import './Category.css'
import TooltipModal from "components/TooltipModal";

type Props = {
    index: number
    category: CategoryClass
}

const Category = ({category, index}: Props) => {
    return (
        <div className={'category-container'}>
            <div className=" bg-secondary-light mt-3 p-3">
                <div className={'text-start mb-0 category'}>
                    {index}. {category.name}
                    {
                        category.tooltip &&
                        <TooltipModal tooltip={category.tooltip}/>
                    }
                </div>

            </div>

            {
                category.subCategories.map((subCategory: SubCategoryClass, i) =>
                    <SubCategory key={i} categoryIndex={index} index={i + 1} subCategory={subCategory}/>)
            }

        </div>
    );
}

export default Category;
