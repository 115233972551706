import React from 'react';
import './App.css';
import logo from 'assets/img/logo.svg';

import Indicator from 'components/Indicator';
import {Indicator as IndicatorClass} from "lib/indicator";
import {RadarChart} from "components/RadarChart";
import {useChartData} from "hooks/use-chart-data";
import {useLanguage} from "hooks/use-language";
import Legende from "components/Legende";


const App = () => {


    const {indicatorCalculator} = useChartData()
    const {language, setLanguage} = useLanguage()

    return (
        <>
            <div className={'header bg-primary d-flex justify-content-between flex-column flex-sm-row'}>
                <div className={'d-flex align-items-center'}>
                    <img alt='' src={logo} className={'img-fluid'}/>
                    <span className={'ms-4 text-white fw-bold d-xl-inline-block d-none'}>Reflectie- en Evaluatietool Beleid Grensoverschrijdend Gedrag</span>
                </div>

                <div className={'d-flex align-items-center flex-column flex-sm-row'}>
                    <Legende/>
                    <a target="_blank"
                       href="files/handleiding.pdf"
                       className={'text-white fw-bold  p-2 text-decoration-none'}>
                        Handleiding
                    </a>
                    <select className={'form-select ms-2'}
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}>
                        <option value={'NL'}>Nederlands</option>
                        {/*<option value={'EN'}>English</option>*/}
                    </select>
                </div>
            </div>
            <div className={"container-fluid content"}>
                <div className={"row h-100 d-flex"}>
                    <div className={'col-12'}>
                        <h4 className={'d-xl-none d-block text-center my-4'}>Reflectie- en Evaluatietool Beleid Grensoverschrijdend Gedrag</h4>
                    </div>
                    <div className={'col-xl-6 h-100'}>
                        <RadarChart/>
                    </div>
                    <div className={'col-xl-6  questions-container '}>
                        <div className={'p-4 p-lg-0'}>
                            {
                                indicatorCalculator.indicators.map((indicator: IndicatorClass, index: number) => {
                                        const questionNumber = indicatorCalculator.indicators.slice(0, index)
                                            .map((indicator: any) => indicator.categories.length)
                                            .reduce((a: number, b: number) => a + b, 0)
                                        return <Indicator key={index} indicator={indicator}
                                                          questionNumber={questionNumber}/>
                                    }
                                )
                            }
                        </div>

                        <button
                            className={'scroll-button mx-auto d-block d-lg-none border-0 text-white fw-bold bg-primary mb-4'}
                            onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth"
                                })
                            }}>
                            TERUG NAAR BOVEN
                        </button>

                    </div>


                </div>
            </div>
        </>
    );
}

export default App;
