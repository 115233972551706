import {CategoryJSON, SubCategoryJSON} from "types/types";
import {SubCategory} from "lib/sub-category";


export class Category {
    public name: string
    public weight: number
    public subCategories: SubCategory[]
    public tooltip ?: any

    constructor(category: CategoryJSON) {
        this.name = category.name
        this.weight = category.subCategories.reduce((sum: number, subCategory: SubCategoryJSON) => sum + subCategory.weight, 0)
        this.subCategories = category.subCategories.map(subCategoryJSON => new SubCategory(subCategoryJSON))
        this.tooltip = category.tooltip
    }


    getWeightedValue() {
        return this.subCategories.reduce((sum:number, subCategory) => sum + subCategory.getWeightedValue(), 0)
    }

    isCompletelyFilledIn(){
        return this.subCategories.map(subCategories => subCategories.filledIn).every(boolean => boolean)
    }

}
