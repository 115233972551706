import {IndicatorJSON} from "types/types";
import {Indicator} from "lib/indicator";

export class IndicatorCalculator {
    public weight: number
    public indicators : Indicator[]


    constructor(indicators: IndicatorJSON[]) {
        this.weight = 0
        this.indicators = indicators.map( indicatorJSON => new Indicator(indicatorJSON))
    }


    getChartData(){
        return this.indicators.map(indicator => ({
            label: indicator.label || indicator.name,
            value: indicator.getWeightedValue(),
            completed: indicator.isCompletelyFilledIn()
        }))
    }

}
