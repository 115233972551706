import React, {useState} from "react";
import {IndicatorCalculator} from "lib/indicator-calculator";
import {INDICATORS} from "config/indicators";

const ChartDataContext = React.createContext<any>(null)

const indicatorCalculator = new IndicatorCalculator(INDICATORS)


export const ChartDataProvider = ({children}:any) => {
    const [chartData, setChartData] = useState(indicatorCalculator.getChartData())

    const updateChart = () => {
        const chartData = indicatorCalculator.getChartData()
        setChartData(chartData)
    }

    return (
        <ChartDataContext.Provider value={{chartData, setChartData, updateChart, indicatorCalculator}}>
            {children}
        </ChartDataContext.Provider>
    )
}

export const useChartData = () => React.useContext(ChartDataContext)
