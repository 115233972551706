import {SubCategoryJSON} from "types/types";

export class SubCategory {
    public name: string;
    public weight: number;
    public value: number = 0;
    public filledIn : boolean = false;
    public tooltip ?: any;


    constructor(subCategoryJSON: SubCategoryJSON) {
        this.name = subCategoryJSON.name
        this.weight = subCategoryJSON.weight
        this.tooltip = subCategoryJSON.tooltip
    }


    setValue(value: number) {
        this.value = value
        this.filledIn = true
    }

    getWeightedValue() {
        return this.weight * this.value
    }
}
