import {Category} from "lib/category";
import {IndicatorJSON} from "types/types";

export class Indicator {
    public name: string
    public weight: number
    public categories : Category[]
    public label: any = ''

    constructor(indicator: IndicatorJSON) {
        this.name = indicator.name
        this.categories = indicator.categories.map( categoryJSON => new Category(categoryJSON))
        this.weight = this.categories.reduce((sum: number, category: Category) => sum + category.weight, 0)
        this.label = indicator.label || this.label
    }


    getWeightedValue(){
        const weightedValues = this.categories.reduce((sum:number, category) => sum + category.getWeightedValue(), 0)
        return (weightedValues / this.weight) * 10
    }

    isCompletelyFilledIn(){
        return this.categories.map(categories => categories.isCompletelyFilledIn()).every(boolean => boolean)
    }

}
